
    .record-container {
        height: calc(100% - 40px);
        background: #F2F2F2;
        padding: 20px 0;
        .record-content {
            height: 100%;
            display: flex;
            flex-direction: column;
            width: 1200px;
            margin: 0 auto;
        }
    }
    ::v-deep .el-breadcrumb__inner {
        color: #000;
    }
